<template>
  <div>
    <!-- login -->
    <section id="login-page">
      <div class="container my-5">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card shadow p-3 bg-body rounded">
              <div class="card-header mb-3 px-4">
                <h3 class="card-title my-1 text-center">{{$t('message.tajimi-merchant-title')}}</h3>
              </div>
              <div class="card-body p-3 m-1">
                <div class="login error-message"></div>
                <form id="form-login" method="POST" @submit.prevent="login">
                  <div>
                     <label>
                      {{ $t("message.label-email") }}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      placeholder="Email"
                      class="form-control"
                      name="email"
                      v-model="dataLogin.email"
                    />
                  </div>
                  <div>
                    <label>
                      {{ $t("message.label-password") }}
                    </label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      placeholder="Password"
                      type="password"
                      class="form-control"
                      name="password"
                      v-model="dataLogin.password"
                    />
                  </div>
                  <div>
                    <router-link to="/forgotPassword">
                      <span>{{ $t("message.forgot-password") }}</span>
                    </router-link>
                  </div>
                  <div class="d-grid  mt-4">
                    <v-btn type="submit" @click.prevent="login" depressed color="primary">
                      {{$t('message.login')}}
                    </v-btn>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      dataLogin: {
        email: "",
        password: "",
        message: {}
      },
    };
  },
  methods: {
    login() {
      this.dataLogin.message.title = this.$t('message.login-success-message')
      this.dataLogin.message.button_ok = this.$t('message.ok')
      this.$store.dispatch("auth_module/login", this.dataLogin);
    },
  },
  created() {
  }
};
</script>

<style></style>
